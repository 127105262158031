import { useLocation } from 'react-router-dom';
import { useSession } from './useSession';
import { useEffect } from 'react';
import { environment } from 'environments/environment';

const V2_STORAGE_FLAG = 'v2_deal_ui';
const v2Paths = ['/deals', '/inventory/packages', '/segments', '/taxonomy', '/deals-health'];

const redirectToV2 = location => {
  const target = `/v2${location.pathname.replace('/v2', '')}${location.search}`;

  window.history.replaceState({ key: 'default', usr: location.state }, '', target);
  window.location.replace(target);
};

const isV2UIAllowed = session => {
  if (session.feature_flags?.v2dealUI) {
    return true;
  }

  return false;
};

const isV2Pathname = (pathname: string) => {
  return v2Paths.some(v2Path => pathname.startsWith(v2Path));
};

const shouldRedirectToV2 = location => {
  if (JSON.parse(localStorage.getItem(V2_STORAGE_FLAG) || 'false') === true && isV2Pathname(location.pathname)) {
    return true;
  }

  return false;
};

export const useVersionRedirect = () => {
  const session = useSession();
  const location = useLocation();

  const setRedirectionFlag = () => {
    if (!isV2UIAllowed(session)) {
      return;
    }

    localStorage.setItem(V2_STORAGE_FLAG, 'true');
    window.dispatchEvent(new Event('storage'));
  };

  useEffect(() => {
    // Check initial local storage state and redirect if v2 is set

    if (
      environment.production &&
      isV2UIAllowed(session) &&
      JSON.parse(localStorage.getItem(V2_STORAGE_FLAG) || 'false') === false
    ) {
      setRedirectionFlag();
    }

    if (isV2UIAllowed(session) && shouldRedirectToV2(location)) {
      redirectToV2(location);
    }

    const storageUpdateCallback = () => {
      if (isV2UIAllowed(session) && shouldRedirectToV2(location)) {
        redirectToV2(location);
      }
    };

    window.addEventListener('storage', storageUpdateCallback);

    return () => {
      window.removeEventListener('storage', storageUpdateCallback);
    };
  }, [location, session]);

  return { isV2UIAllowed, setRedirectionFlag };
};
