export enum AudienceStatus {
  PUBLISH_FAILED = 'publish_failed',
  ACTIVATE_FAILED = 'activate_failed',
  PUBLISHING = 'publishing',
  ACTIVATED = 'activated',
  EXPORTING = 'exporting',
  EXPORTED = 'exported',
  EXPORT_FAILED = 'export_failed',
  DRAFT = 'draft',
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
  EXPIRE_FAILED = 'expire_failed',
}

export enum ExportingStatus {
  INIT = 'init',
  EXPORT_WORKER_DONE = 'export_worker_done',
  EXPORT_DOWNLOADING = 'export_downloading',
  EXPORT_DOWNLOADING_DONE = 'export_downloading_done',
  EXPORT_PROCESSING = 'export_processing',
  EXPORT_PROCESSING_DONE = 'export_processing_done',
}

const EDITABLE_FAILED_STATUSES = [
  AudienceStatus.PUBLISH_FAILED,
  AudienceStatus.ACTIVATE_FAILED,
  AudienceStatus.EXPORT_FAILED,
];

export const FAILED_STATUSES = [...EDITABLE_FAILED_STATUSES, AudienceStatus.EXPIRE_FAILED];

// migrate from canEditAudience
export const EDITABLE_STATUSES = [...EDITABLE_FAILED_STATUSES, AudienceStatus.EXPORTED, AudienceStatus.EXPIRED];

export const FORBIDDEN_STATUSES = [AudienceStatus.EXPIRED, AudienceStatus.EXPIRING];

// migrate from canCreateDealWithAudience
export const UNSUPPORTED_STATUSES_FOR_DEAL = [...FAILED_STATUSES, ...FORBIDDEN_STATUSES, AudienceStatus.DRAFT];

// migrate from audienceList -> tableColumns
export const PROCESSING_STATUSES = [AudienceStatus.PUBLISHING, AudienceStatus.ACTIVATED, AudienceStatus.EXPORTING];

export const ACTIVE_STATUSES = [AudienceStatus.EXPORTED];

export const INACTIVE_STATUSES = [...PROCESSING_STATUSES, AudienceStatus.DRAFT];

export const audienceExpiredText = 'Audience hasn’t been refreshed in more than 90 days and is expired.';
export const audienceSoonWillBeExpiredText = 'Audience is pending expiration due to 90 day expiration window.';

export enum SegmentStatus {
  READY = 'ready',
  AWAITING_DATA = 'awaiting_data',
  PUBLISHING = 'publishing',
  ERROR = 'error',
  EXPIRED = 'expired',
}

export const segmentExpiredText = fromDeal =>
  fromDeal
    ? 'Audience associated with a given deal has expired segment.'
    : 'Segment data is expired and cannot be used for audience creation';

export const segmentSoonWillBeExpired = (fromDeal, segmentDaysRemaining) =>
  fromDeal
    ? `Audience associated with a given deal has segments that will expire in next ${segmentDaysRemaining} ${
        segmentDaysRemaining === 1 ? 'day' : 'days'
      }.`
    : `Segment is going to expire within the next ${segmentDaysRemaining} ${
        segmentDaysRemaining === 1 ? 'day' : 'days'
      }`;

export const segmentArchivedText = fromDeal =>
  fromDeal
    ? 'Audience includes a segment that is archived'
    : 'Segment is archived and cannot be used for audience creation';
