import { AudienceStatus, SegmentStatus } from 'modules/audiences/constants';
import moment from 'moment';

export function isSoonToBeExpired(estimatedExpiryDate, status) {
  if (status === AudienceStatus.EXPIRED || status === SegmentStatus.EXPIRED) {
    return false;
  } else if (!estimatedExpiryDate) {
    return false;
  }

  const daysRemaining = moment.duration(new Date(estimatedExpiryDate).getTime() - new Date().getTime()).asDays();

  return daysRemaining <= 10;
}

export function daysToExpiration(estimatedExpiryDate) {
  if (!estimatedExpiryDate) {
    return null;
  }

  const daysRemaining = Math.round(
    moment.duration(new Date(estimatedExpiryDate).getTime() - new Date().getTime()).asDays(),
  );

  return daysRemaining < 0 ? 0 : daysRemaining;
}

export function isExpired(status) {
  return status === AudienceStatus.EXPIRED || status === SegmentStatus.EXPIRED;
}

export function segmentDaysRemaining(segments) {
  return segments
    .filter(segment => isSoonToBeExpired(segment.expiryDate, segment.segmentStatus))
    .map(({ expiryDate }) => daysToExpiration(expiryDate))
    .sort((a, b) => a - b);
}
